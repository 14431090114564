import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translate(0, -55%);
  z-index: 12;
  max-width: 250px;
  cursor: pointer;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    right: 25px;
  }
  // @media (max-width: ${props => props.theme.responsive.medium}) {
  //   padding-bottom: 25px;
  //   transform: rotate(90deg);
  //   transform-origin: 100% 0;
  // }
`
const Mask = styled.div`
width: 150px;
height: 150px;
position: relative;
overflow: hidden;
border-radius: 50%;
display: none;
@media (min-width: ${props => props.theme.responsive.medium}) {
  display: inline-block;
}
img{
  position: absolute;
  width: initial;
  height: initial;
  max-width: 250px;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
`

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  a {
    z-index: 2;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-decoration: none;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      transform: rotate(90deg);
    }
    img{
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    }
    &:hover {
      @media (min-width: ${props => props.theme.responsive.medium}) {
        transform: scale(1.15, 1.15);
      }
      img{
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      }
    }

    span{
      color: black;
      font-size: 18px;
      letter-spacing: 1px;
      display: block;
      text-align: center;
      margin-bottom: 10px;
    }

  }
`

const NextLink = styled(Link)`
  margin-left: auto;
  order: 2;
` 

const PostLinks = props => {
  
  return (
    <Wrapper>
      <Box>
        {props.next && (
          <NextLink to={`/${props.next.slug}/`}>
            <span className="next-text">Next Project</span>
            <Mask>
              <img src={props.next.heroImage.ogimg.src}/>
            </Mask>
          </NextLink>
        )}
      </Box>
    </Wrapper>
  )
}

export default PostLinks
