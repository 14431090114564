import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import HeroHeaderSlider from '../components/HeroHeaderSlider'
import ContainerWork from '../components/ContainerWork'
import PageBody from '../components/PageBody'
import PageSidebar from '../components/PageSidebar'
// import TagList from '../components/TagList'
import PostDate from '../components/PostDate'
import SEO from '../components/SEO'
import styled from 'styled-components'
import PostTitle from '../components/PostTitle'

const MainContent = styled.div`
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: 70%;
    padding-right: 5em;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    width: 70%;
    padding-right: 7.5em;
  }
`

class PostTemplate extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let hamburger = document.getElementById('hamburger')
    hamburger.classList.add('hidden')

    let closeproject = document.getElementById('closeproject')
    closeproject.classList.add('show')

    let hidelogo = document.getElementById('logo')
    hidelogo.classList.add('hide')
  }
  render() {
    const data = this.props.data
    const pageContext = this.props.pageContext
    const {
      title,
      projectSubtitle,
      slug,
      photoSet,
      body,
      credits,
      publishDate,
    } = data.contentfulWork
    const postNode = data.contentfulWork
    const previous = pageContext.prev
    const next = pageContext.next
    var images = photoSet
    return (
      <Layout>
        <Helmet>
          <title>{`${title} - ${config.siteTitle}`}</title>
        </Helmet>
        <SEO pagePath={slug} postNode={postNode} postSEO />
        <HeroHeaderSlider
          title={title}
          images={images}
          subtitle={projectSubtitle}
          prev={previous}
          next={next}
          fade
        />

        <ContainerWork>
          <MainContent>
            {/* {tags && <TagList tags={tags} />} */}
            <PostDate date={publishDate} />
            <PostTitle>{title}</PostTitle>
            <PageBody body={body} />
          </MainContent>
          {credits && <PageSidebar credits={credits} slug={slug} />}
        </ContainerWork>
        {/* <PostLinks previous={previous} next={next} /> */}
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    contentfulWork(slug: { eq: $slug }) {
      title
      projectSubtitle
      slug
      metaDescription {
        internal {
          content
        }
      }
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      tags {
        title
        id
        slug
      }
      heroImage {
        title
        fluid(maxWidth: 2200) {
          ...GatsbyContentfulFluid_withWebp
        }
        ogimg: resize(width: 2200) {
          src
          width
          height
        }
      }
      photoSet {
        __typename
        ... on ContentfulWorkPhoto {
          vertical
          dark
          photo {
            fluid(maxWidth: 2000, quality: 70) {
              ...GatsbyContentfulFluid_withWebp
            }
            file {
              contentType
              url
            }
          }
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      credits {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`

export default PostTemplate
