import React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  font-size: 2em;
  margin: 0rem 0 1rem 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 3em;
    margin: 0rem 0 2rem 0;
  }
  text-transform: capitalize;
  font-weight: 300;
  line-height: 1.2;
  span {
    margin: 0 0 0 0.25em;
  }
  a {
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const PostTitle = props => {
  return <Title small={props.small}>{props.children}</Title>
}

export default PostTitle
