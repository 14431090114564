import React from 'react'
import styled from 'styled-components'
import PostLinks from './PostLinks'

const NextProjectPreview = props => {
    if (props.id === props.length) {
        return (
        <PostLinks previous={props.previous} next={props.next} />
        )
    } else {
        return null
    }
}

export default NextProjectPreview