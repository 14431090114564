import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
require('prismjs/themes/prism.css')
import facebook from '../images/facebook-icon.svg'
import spotify from '../images/spotify-icon.svg'
import instagram from '../images/instagram-icon.svg'
import arrowRightDark from '../images/arrow-right-dark.svg'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Sidebar = styled.div`
  margin: 0 auto 0 auto;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: 30%;
    margin: 175px auto 0 auto;
  }
  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }

  p {
    line-height: 1.6;
    margin: 0 0 1em 0;
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      margin: 0 0 2em 0;
    }
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }
`
const SidebarBody = styled.section`
  strong {
    display: block;
  }
`
const SidebarSocial = styled.div`
  padding: 30px 0;
  margin: 20px 0;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0;
    li {
      display: inline-block;
    }
  }
`
const SidebarContact = styled.div`
  padding: 25px 0;
  h3 {
    margin-bottom: 5px;
    font-size: 1.17em;
  }
  a {
    text-decoration: none;
    img {
      width: 10px;
      display: inline;
      margin-left: 5px;
    }
  }
`
const Brand = styled.img`
  width: 25px;
  max-height: 20px;
`

const PageSidebar = props => {
  return (
    <Sidebar>
      <SidebarBody
        dangerouslySetInnerHTML={{
          __html: props.credits.childMarkdownRemark.html,
        }}
      />
      <SidebarSocial>
        <ul>
          <li>
            <OutboundLink
              href="https://www.facebook.com/mauricevandermeijsdotcom/"
              target="_blank"
              rel="noopener norefferer"
            >
              <Brand src={facebook} alt="Facebook" />
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              href="https://open.spotify.com/user/1147824736/playlist/16DgC8UVONqCQmrdYTUTne?si=4IEQb1EkQQGVqeJyVVrwfg"
              target="_blank"
              rel="noopener norefferer"
            >
              <Brand src={spotify} alt="Spotify" />
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              href="https://www.instagram.com/maurice_meijs/"
              target="_blank"
              rel="noopener norefferer"
            >
              <Brand src={instagram} alt="Instagram" />
            </OutboundLink>
          </li>
        </ul>
      </SidebarSocial>
      <SidebarContact>
        <h3>Like what you see?</h3>
        <Link to="/contact" alt="contact">
          Get in Touch{' '}
          <img src={arrowRightDark} title="Get In Touch" width="15" />
        </Link>
      </SidebarContact>
    </Sidebar>
  )
}

export default PageSidebar
